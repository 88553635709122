<script lang="ts" setup>
import { useForm } from "vee-validate";
import * as z from "zod";

import { toTypedSchema } from "@vee-validate/zod";

definePageMeta({
  layout: "form"
});

const { t } = useI18n();

const validationSchema = toTypedSchema(
  z.object({
    username: z.string({ message: t("form.validation.required") }).email(t("form.validation.email")),
    password: z
      .string({ message: t("form.validation.required") })
      .min(8, t("form.validation.charsMinLength", { length: 8 }))
  })
);

const form = useForm({ validationSchema });

const route = useRoute();
const returnTo = route.query.return_to as string | undefined;

const { flow, proceedWithFlow } = useOryFlow({
  createFlow: (sdk) => sdk.createBrowserLoginFlow(),
  getFlow: (sdk, requestParameters) => sdk.getLoginFlow(requestParameters),
  errorHandling: {
    form,
    defaultNav: "/login",
    fatalToDash: true
  }
});

const { $toast } = useNuxtApp();

watch(flow, (value) => {
  if (value) {
    value?.ui.messages?.forEach((message) => {
      if (message.id === OryMessageId.invalidCredentials) {
        return $toast.error(t("kratos.invalidCredentials.title"), {
          description: t("kratos.invalidCredentials.description")
        });
      }
      if (message.id === OryMessageId.authenticationRequired) {
        return $toast.warning(t("kratos.authenticationRequired.description"));
      }
      console.error("Found unknown message", message);
      return $toast.error(t("kratos.unknownMessage.title"), {
        description: message.text
      });
    });
  }
});

const onSubmit = form.handleSubmit(async (values) => {
  await proceedWithFlow(async (sdk, flow) => {
    if (!flow) {
      await navigateTo("/login");
      return;
    }

    await sdk.updateLoginFlow({
      flow: flow.id,
      updateLoginFlowBody: {
        csrf_token: getCsrfToken(flow),
        identifier: values.username,
        password: values.password,
        method: "password"
      }
    });

    await navigateTo(returnTo || "/", {
      external: !!returnTo?.includes("/api/v1/")
    });
  });
});
</script>

<template>
  <FormCard>
    <h1 class="font-serif text-3xl text-center pb-8">{{ $t("login.title") }}</h1>
    <form class="flex flex-col w-full gap-4" @submit="onSubmit">
      <BasicFormField
        v-model="form.values.username"
        name="username"
        label="form.label.email"
        type="email"
        autofocus
        autocomplete="username"
      />
      <BasicFormField
        v-model="form.values.password"
        name="password"
        label="form.label.password"
        type="password"
        autocomplete="current-password"
      />
      <BasicButton type="submit"> {{ $t("login.signIn") }} </BasicButton>
    </form>

    <div class="mt-8 w-full">
      <p>
        {{ $t("login.signup.callToAction") }}
        <BasicButton variant="link" to="/registration">{{ $t("login.signup.button") }}</BasicButton>
      </p>
    </div>
    <div class="w-full">
      <BasicButton variant="link" text="login.signup.forgotPassword" to="/recovery" class="mx-auto" />
    </div>
  </FormCard>
  <p
    :class="`
    max-sm:hidden font-serif font-light text-[7vw] leading-[1] tracking-[-0.04em]
    absolute left-0 bottom-0 p-[2vw]
    `"
  >
    {{ $t("login.slogan") }}
  </p>
</template>
